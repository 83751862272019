import React from 'react';
import { Link } from "gatsby"


function Formulario() {
    return (
        <>
            <div className="rounded-2xl p-5 xl:p-11 bg-black/50 my-32">
                <p className=" text-white text-center text-3xl mb-10">Solicitar Presupuesto</p>
                <form action="" className="flex flex-col gap-5">
                    <input type="text" placeholder="Nombre y Apellidos" className="bg-white p-2  text-sm text-black rounded-xl  placeholder:text-text" />
                    <input type="email" placeholder="Email" className="bg-white p-2  text-sm text-black rounded-xl  placeholder:text-text" />
                    <input type="text" placeholder="Teléfono" className="bg-white p-2  text-sm text-black rounded-xl  placeholder:text-text" />
                    <textarea name="" id="" cols="30" rows="5" placeholder="Breve descripción de los trabajos a realizar" className="bg-white p-2 text-sm text-black rounded-xl mb-3 placeholder:text-text"></textarea>
                    <div className="flex mb-10">
                        <input type="checkbox" className="mr-2 mt-1" />
                        <p className="text-white text-sm">He leído y acepto la <Link to={'/politica-privacidad'} className="font-bold hover:text-primary">política de privacidad</Link>
                        </p>
                    </div>
                    <button href="#"
                        className="whitespace-nowrap text-lg font-medium bg-primary py-2 px-6 rounded-xl hover:text-primary hover:bg-white hover:border-primary text-white transition-all duration-300 text-center">
                        Enviar mensaje </button>
                </form>
            </div>
        </>
    );
}

export default Formulario;
