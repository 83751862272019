import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import Layout from "../components/layout"
import Img from "gatsby-image"
import { graphql } from "gatsby";

import Formulario from "../components/Formulario"
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import animateReveal from "../components/gs_reveal";


export default function ReformasCiudad({ data }) {
  console.log(data)
  gsap.registerPlugin(ScrollTrigger);
  const revealRefs = useRef([]);
  revealRefs.current = [];
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    let scrollTriggers = [];
    scrollTriggers = animateReveal(revealRefs.current);
    return () => {
      scrollTriggers.forEach((t) => t.kill(true));
    };
  }, []);

  const addToRevealRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };
  
  const [isActive, setIsActive] = useState(false);

  return (
    <Layout>
      <main>
        <div
          className="bg-[url('https://reformas.top/background/reformas-ciudad.webp')] bg-fixed bg-cover bg-bottom bg-no-repeat">
          <div className=" container flex flex-col xl:flex-row justify-between py-5">
            <div className=" flex items-center justify-center 2xl:px-10">
               <h1
                dangerouslySetInnerHTML={{ __html: data.wpReformaCiudad.title }}
                className="text-white text-center xl:text-left text-4xl tracking-tight sm:text-6xl lg:text-6xl 2xl:text-7xl leading-[1.2] mb-10"
              />
            </div>
            <div>
              <Formulario />
            </div>
          </div>
        </div>
        <section style={{ "background": "transparent linear-gradient(0deg, #f2f4f9 0%, #FFFFFF00 30%) 0% 0% no-repeat padding-box" }}
          className="py-20">
          <div className="max-w-4xl mx-auto">
            <p className=" text-text text-xl text-center gs_reveal gs_reveal_fromLeft"
              dangerouslySetInnerHTML={{ __html: data.wpReformaCiudad.content }}

            />
          </div>
          <div className="py-20 container">
            <h2 className="mt-6 text-5xl text-black font-bold text-center mb-20">¿Que hacemos?</h2>
            <div className="mt-20">
              <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-5">
                <div className="bg-white rounded-3xl shadow-2xl p-5">
                  <div className="flex flex-col items-left">
                    <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3 mb-3"><span ref={addToRevealRefs}
                      className="ico-service block icon-kitchen"></span></div>
                    <div
                      dangerouslySetInnerHTML={{ __html: data.wpReformaCiudad.contentenido.reformaDeCocinas }}

                    />

                  </div>
                </div>

                <div className="bg-white rounded-3xl shadow-2xl p-5">
                  <div className="flex flex-col items-left">
                    <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3 mb-3"><span ref={addToRevealRefs}
                      className="ico-service block icon-store"></span></div>
                   <div
                      dangerouslySetInnerHTML={{ __html: data.wpReformaCiudad.contentenido.reformasDeLocales }}
                    />
                  </div>
                </div>
                <div className="bg-white rounded-3xl shadow-2xl p-5">
                  <div className="flex flex-col items-left">
                    <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3 mb-3">
                      <span ref={addToRevealRefs} className="ico-service block icon-living-room"></span></div>

                      <div
                      dangerouslySetInnerHTML={{ __html: data.wpReformaCiudad.contentenido.reformasDePisos }}

                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col-reverse lg:flex-row container">
            <div className="flex flex-col justify-center items-center lg:items-start">
              <p className=" text-4xl lg:text-5xl text-black font-bold mb-5">Pide tu reforma</p>
              <p className="mb-5">Pide presupuesto sin compromiso, te explicamos cualquier duda que tengas</p>
              <button
                className="px-8 py-2 bg-primary rounded-xl text-base font-bold text-white focus:outline-none focus:ring-2 focus:ring-red-300 focus:ring-offset-1 hover:bg-white hover:text-primary border-2 border-primary transition-all duration-300">Contáctanos</button>
            </div>
            <div className="w-1/2">
              <Img fluid={data.reforma.childImageSharp.fluid} alt={`Pide tu reforma`} />
            </div>
          </div>
        </section>
        <section className="my-20">
          <div className="container">
            <div>
              <h2 className="mt-6 text-5xl text-text font-bold text-center mb-20">Nuestros servicios</h2>
            </div>
            <div ref={addToRevealRefs} className="flex flex-col lg:flex-row items-center my-20 w-11/12 mx-auto">
              <div className="basis-1/2">
                <Img fluid={data.banos.childImageSharp.fluid} alt={`Reformas de Baño`} />
              </div>
              <div ref={addToRevealRefs} className="gs_reveal_fromRight lg:basis-1/2 lg:bg-gray-bg lg:bg-opacity-25 lg:rounded-r-3xl p-10">
              <div className=" text-text text-xl text-center gs_reveal gs_reveal_fromLeft"
              dangerouslySetInnerHTML={{ __html: data.wpReformaCiudad.contentenido.reformasDeBanos }} />
              </div>
            </div>
            <div className="flex flex-col-reverse lg:flex-row items-center my-28 w-11/12 mx-auto">
              <div ref={addToRevealRefs} className="gs_reveal_fromLeft lg:basis-1/2 lg:bg-gray-bg lg:bg-opacity-25 lg:rounded-r-3xl p-10">
              <div className=" text-text text-xl text-center gs_reveal gs_reveal_fromLeft"
              dangerouslySetInnerHTML={{ __html: data.wpReformaCiudad.contentenido.reformasDeCasas }} />
              </div>
              <div ref={addToRevealRefs} className="basis-1/2">
                <Img fluid={data.cocina.childImageSharp.fluid} alt={`Reformas de Cocinas`} />
              </div>
            </div>
       {/*      <div ref={addToRevealRefs} className="flex flex-col lg:flex-row items-center my-20 w-11/12 mx-auto">
              <div className="basis-1/2">
                <Img fluid={data.comunidad.childImageSharp.fluid} alt={`Reformas de comunidades`} />
              </div>
               <div ref={addToRevealRefs} className="gs_reveal_fromRight lg:basis-1/2 lg:bg-gray-bg lg:bg-opacity-25 lg:rounded-r-3xl p-10">
                <h2>Reformas de Comunidades</h2>
                <p>Las reformas de comunidades sirven para renovar todos los espacios comunes de un edificio y hacerlos más
                  prácticos, cómodos y seguros para todos. Llevamos a cabo todas nuestras obras de acuerdo a las normas de
                  urbanismo e inclusión de las personas con movilidad reducida. Reparamos y realizamos todo tipo de
                  ampliaciones o modificaciones de las áreas verdes, piscinas, canchas deportivas y parques en busca de los
                  mejores resultados para toda la comunidad.</p>
              </div> 
            </div> */}
          </div>
        </section>
        <section>

          <div className="container" id="accordion-collapse" data-accordion="collapse">
            <div id="accordion-collapse-heading-1">
              <button type="button" className="flex justify-between items-center p-5 w-full font-medium text-left rounded-t-xl border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                <div dangerouslySetInnerHTML={{ __html: data.wpReformaCiudad.contentenido.faq1Title }} />
                <svg data-accordion-icon className="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" ></path></svg>
              </button>
            </div>
            <div id="accordion-collapse-body-1" className="">
              <p className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 mb-0" dangerouslySetInnerHTML={{ __html: data.wpReformaCiudad.contentenido.faq1 }} />
            </div>

            <div id="accordion-collapse-heading-2">
              <button type="button" className="flex justify-between items-center p-5 w-full font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
                <h3>¿Cuánto se tarda en recibir el presupuesto?</h3>
                <svg data-accordion-icon className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" ></path></svg>
              </button>
            </div>
            <div id="accordion-collapse-body-2" className="">
              <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700">
              <p dangerouslySetInnerHTML={{ __html: data.wpReformaCiudad.contentenido.faq2 }} />
              </div>
            </div>
            <div id="accordion-collapse-heading-3">
              <button type="button" className="flex justify-between items-center p-5 w-full font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
              <div dangerouslySetInnerHTML={{ __html: data.wpReformaCiudad.contentenido.titleFaq3 }} />
                <svg data-accordion-icon className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" ></path></svg>
              </button>
            </div>
            <div id="accordion-collapse-body-3" className="">
              <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
              <p dangerouslySetInnerHTML={{ __html: data.wpReformaCiudad.contentenido.faq3 }} />
              </div>
            </div>

          </div>
        </section>
      </main>
    </Layout>
  )
}


export const query = graphql`
query CiudadDetail($slug: String) {
  wpReformaCiudad:wpReformaCiudad(slug: {eq: $slug}) {
    slug
    title
    content
    field {
      email
      telFono
    }
    status
    contentenido {
      faq1
      faq2
      faq3
      reformasDeCasas
      reformaDeCocinas
      reformasDeBanos
      reformasDeLocales
      reformasDePisos
      reformasObras
      faq1Title
      titleFaq2
      titleFaq3
    }
  },
  reforma:file(relativePath: {eq: "pide-tu-reforma.webp"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  },
  oficinas:file(relativePath: {eq: "reformas-ciudad.webp"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  },
  banos:file(relativePath: {eq: "reformas-de-banos-en-ciudad.webp"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    },
  comunidad:file(relativePath: {eq: "reformas-comunidad-ciudad.webp"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    },
    cocina:file(relativePath: {eq: "reformas-de-cocina.webp"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
}
`